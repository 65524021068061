import React, { useContext } from "react";
import { appState } from "../App";
import { translations } from "../config/translations.js";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "../config/Common.css";
import "./styles/About.css";

const About = () => {
  const state = useContext(appState);
  const language = state.language;

  let mainSponsorsGrid = [];
  let mediaSponsorsGrid = [];
  let nociSponsorsGrid = [];
  let req = require.context("../../public/images/pokrovitelji/glavni", false, /.*\.png$/);
  req.keys().forEach(function(key) {
    mainSponsorsGrid.push(
      <div className="sponsors-image-container" key={key}>
        <img src={"/images/pokrovitelji/glavni/" + key.substring(2, key.length)} alt="" />
      </div>
    );
  });

  req = require.context("../../public/images/pokrovitelji/media", false, /.*\.png$/);
  req.keys().forEach(function(key) {
    mediaSponsorsGrid.push(
      <div className="sponsors-image-container" key={key}>
        <img src={"/images/pokrovitelji/media/" + key.substring(2, key.length)} alt="" />
      </div>
    );
  });

  req = require.context("../../public/images/pokrovitelji/noci", false, /.*\.png$/);
  req.keys().forEach(function(key) {
    nociSponsorsGrid.push(
      <div className="sponsors-image-container" key={key}>
        <img src={"/images/pokrovitelji/noci/" + key.substring(2, key.length)} alt="" />
      </div>
    );
  });

  return (
    <div className="main-container">
      <div className="title">{translations[language].festival_name}</div>
      <div className="normal-text">
        <div
          className="symposium-about-text"
          dangerouslySetInnerHTML={{ __html: translations[language].about_festival }}
        />
      </div>

      <div className="title sponsors_title">{translations[language].project_partners_main}</div>
      <div className="sponsors-container">{mainSponsorsGrid}</div>

      <div className="title sponsors_title">{translations[language].project_partners_media}</div>
      <div className="sponsors-container">{mediaSponsorsGrid}</div>

      <div className="title sponsors_title">{translations[language].project_partners_noci}</div>
      <div className="sponsors-container">{nociSponsorsGrid}</div>
      <img id="ceska" src="/images/pokrovitelji/ceska.png" alt="" />

      <div className="sipk-sponsors">
        <img src="/images/pokrovitelji/sipk/sipk.png" alt="" />
        <img id="mizs" src="/images/pokrovitelji/sipk/mizs.svg" alt="" />
        <img src="/images/pokrovitelji/sipk/ess.svg" alt="" />
      </div>
      <div className="sipk-sponsors-text">{translations[language].sipk_sponsors}</div>
    </div>
  );
};

export default About;
