import React, { useContext } from "react";
import { getTranslatedString } from "../config/helperFunctions";
import { appState } from "../App";

import "./styles/Culinary.css";
import Loader from "../components/Loader";
import { translations } from "../config/translations";

const Culinary = () => {
  const state = useContext(appState);
  const language = state.language;
  const culinaryEvents = state.culinaryEvents;
  let contentDiv = <Loader key="loader"></Loader>;
  if (culinaryEvents !== null && culinaryEvents !== undefined) {
    contentDiv = (
      <div>
        <div className="title">{getTranslatedString(culinaryEvents.title, language)}</div>
        <div
          className="culinary-container"
          dangerouslySetInnerHTML={{
            __html: getTranslatedString(culinaryEvents.description, language)
              .trim()
              .replace(/(\r\n|\n|\r)/gm, "<br/>")
          }}
        />
      </div>
    );
  } else if (culinaryEvents === undefined) contentDiv = <div>{translations[language].no_program}</div>;

  return <div className="main-container">{contentDiv}</div>;
};

export default Culinary;
