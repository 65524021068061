import React from "react";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const OneNews = props => {
  return (
    <div className="main-container">
      <h3>{props.match.params.id}</h3>
    </div>
  );
};

export default OneNews;
