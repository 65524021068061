import React, { Component } from "react";
import ExpandedArtist from "./ExpandedArtist";
import ArtistSquare from "./ArtistSquare.js";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import Dotdotdot from "react-clamp";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/ArtistGrid.css";

let selectedArtistData = "";

class ArtistGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedArtist: -1, viewExpanded: false };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.setState({});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  onArtistSelect(artistID) {
    if ((this.state.viewExpanded && this.state.selectedArtist === artistID) || artistID === -1) {
      this.setState({ selectedArtist: -1, viewExpanded: false });
    } else {
      this.setState({ selectedArtist: artistID, viewExpanded: true });
    }
  }

  getExpandedArtist(id, oneRow, artistExpanded) {
    return (
      <div key={id}>
        <div className="artist-grid-row">{oneRow}</div>
        <ExpandedArtist artistData={selectedArtistData} locationsList={this.props.locationsList} expanded={artistExpanded} onSelect={this.onArtistSelect.bind(this)} />
      </div>
    );
  }

  getRows(numberOfArtistsPerRow) {
    let artists = this.props.artistsList;
    let oneRow = [];
    let wholeGrid = [];
    let artistExpanded = false;

    if (artists.length !== 0) {
      let id;
      let counter = 0;

      let numberOfArtists = artists.length;

      while (counter < numberOfArtists) {
        let currentArtist = artists[counter];
        id = currentArtist.id;

        let isArtistSelected = id === this.state.selectedArtist;
        if (isArtistSelected) {
          artistExpanded = true;
          selectedArtistData = currentArtist;
        }

        oneRow.push(<ArtistSquare key={"artist" + id} id={id} artist={currentArtist} selected={isArtistSelected} displayDates={this.props.displayDates} onSelect={this.onArtistSelect.bind(this)} />);

        if ((counter + 1) % numberOfArtistsPerRow === 0) {
          wholeGrid.push(this.getExpandedArtist(id, oneRow, artistExpanded));
          oneRow = [];
          artistExpanded = false;
        }

        counter++;
      }

      if (oneRow.length !== 0) wholeGrid.push(this.getExpandedArtist(id + " ", oneRow, artistExpanded));
      if (this.props.symposium === true) oneRow.push(<ArtistSquare key={id} name="Simpozij" symposium={true} />);

      return (
        <div>
          {this.props.displayLocation === null ? (
            <div />
          ) : (
            <div className="location-container">
              <Dotdotdot clamp={1} className="one-line-text-clamp">
                {this.props.displayLocation}
              </Dotdotdot>
            </div>
          )}
          {wholeGrid}
        </div>
      );
    }
  }

  render() {
    let w = window.innerWidth;
    let wholeGrid;

    // corresponds to media queries in ArtistGrid.css
    if (this.props.artistsList !== null) {
      if (w > 1050) {
        wholeGrid = this.getRows(4);
      } else if (w <= 1050 && w >= 750) {
        wholeGrid = this.getRows(3);
      } else if (w <= 750 && w >= 500) {
        wholeGrid = this.getRows(2);
      } else {
        wholeGrid = this.getRows(1);
      }
    } else
      wholeGrid = (
        <div className="artist-grid-loader" key="loader">
          <img alt="loading..." src="/images/loader.svg" />
        </div>
      );

    return <div>{wholeGrid}</div>;
  }
}

export default ArtistGrid;
