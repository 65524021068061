import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

let translations = {
  en: {
    //menu
    program: "EVENTS",
    symposium: "SYMPOSIUM",
    symposium_about: "ABOUT",
    symposium_program: "EVENTS",
    other_events: "ACCOMPANYING EVENTS",
    culinary: "CULINARY EVENTS",
    other: "OTHER EVENTS",
    locations: "VENUES",
    about: "ABOUT",
    media: "PRESS",

    festival_name: "32. Nights in Old Ljubljana Town",
    symposium_name: "International Multidisciplinary Symposium: Sounds, Songs, and Politics",
    project_partners_main: "Main sponsors",
    project_partners_media: "Media sponsors",
    project_partners_noci: "Festival Nights in Old Ljubljana Town is supported by",

    //footer
    donate: "Donate",
    sign_up: "Subscribe",
    imago: "Imago Sloveniae Foundation - The Image of Slovenia",
    contact: "CONTACT",
    donate_five_percent: "DONATE 0.5% OF YOU INCOME",
    immediate_donation: "DONATE RIGHT AWAY",
    sign_up_news: "NEWSLETTER",
    copyrights_reserved: "All copyrights reserved",

    //about festival
    about_festival: `
      <b>Nights in Old Ljubljana Town</b>, the largest street festival in the capital, will take place <b>between 27<sup>th</sup> and 29<sup>th</sup> August 2020</b> and will once again fill the old city center with a cultural beat. In these times of crisis, art is the last refuge of beauty and peace, and it is even more important that cultural events continue to take place. Unfortunately, due to financial constraints, we were forced to significantly reduce this year's edition of the festival. All previous years the Festival Nights in Old Ljubljana Town has been financed mainly with the financial support of sponsors and co-producers, which are much harder to obtain this year due to the consequences of the coronavirus. Despite the difficult situation, we managed to prepare an attractive and high-quality programme for this year's edition of the festival.
      <br>
      <br>
      Culture is among the areas particularly affected by the current crisis. This was the reason we decided to support mainly local artists this year. Therefore it will be possible to listen to some of our best performers and selected foreign musicians living in Slovenia. The programme will also be spiced up by some top foreign bands.
      <br>
      <br>
      <b>All events</b> will traditionally be <b>admission free</b>, as our goal is to bring high quality cultural content closer to the widest possible circle of fellow citizens and visitors to Ljubljana.
      <br>
      <br>
      The theme of the 32<sup>nd</sup> Nights in Old Ljubljana Town International Festival will be <i>traditional sounds of modernity</i>. In parallel, several programme lines of ethno, jazz and classical music will be intertwined, with an emphasis on musical practices that transform traditional influences into modern sound forms. A large part of the programme will include minority musicians, as the <b>International Multidisciplinary Symposium Music and Minorities</b>, which will take place during the festival on the occasion of the 20<sup>th</sup> anniversary of the ICTM (International Society for Traditional Music) minorities study group, will be closely connected with the festival's theme. The symposium is organised in cooperation with the Department of Musicology, Faculty of Arts, University of Ljubljana, the Institute of Music and Ethnography, ZRC SAZU, and KED Folk Slovenia.
      <br>
      <br>
      The festival Nights in Old Ljubljana Town will host <b>23 events</b> at <b>8 venues</b> carefully placed in the magical summer ambience of the old Ljubljana town, where <b>over 100 musicians</b> from <b>12 countries</b> will perform.
      <br>
      <br>
      The festival will take off on Thursday, <b>27 August 2020 at 8 pm at Novi trg</b> with the international ensemble <b>Mi Linda Dama</b> (Italy, Serbia), a top musical ensemble that creates rich musical-dance experience.
      <br>
      <br>
      The Nights in Old Ljubljana Town Festival will offer a striking internationally colored programme. You will be able to listen to tango in a modern disguise with the international project by renowned accordionist Jure Tori <b>Tori Tango</b> (Argentina, Austria, Slovenia), audience favorite and versatile accordion virtuoso <b>Marko Hatlak</b> will perform with his <b>Kapobanda</b>, the folk singer <b>Zvezdana Novaković</b> together with <b>saxophone quartet Roya</b> will show case the project <b>Slovena Voices</b> (Croatia, Poland, Slovenia).
      <br>
      <br>
      In the spirit of the southern rhythms of Andalusia, <b>Vito Mareence Flamenco Duo</b> will perform alongside the established flamenco dancer <b>Ana Pandur</b>, the <b>Mascara Quartet</b> will enchant us with Portuguese fado, a concert of Indian classical music will be given by <b>Lasanthi</b> (Sri Lanka, India, Slovenia). The award-winning British folk innovator Hannah James will perform with her ensemble <b>The JigDoll Ensemble</b> (UK, Austria, Hungary, Iran).
      <br>
      <br>
      With a repertoire filled with nostalgic warmth, the landscapes of time will be painted by the <b>Anbot</b>, ensemble of renowned composer Aldo Kumar, <b>Nova Schola Labacensis</b> will conjure up an evening of Greek Rebetika also called Greek blues, seasoned composer and violinist <b>Matija Krečič</b>, also known as co-author of many Magnifico's hits, will debut his new album Rez/Cut. Among the performers you will also be able to listen to <b>JUNEsHELEN & Alja Petric: Sound Landscapes - Songs & Arias</b>, <b>Acamar Trio</b>, <b>JG3O</b>, and others.
      <br>
      <br>
      The Festival will offer also a rich accompanying program. In addition to the already mentioned <b>International Multidisciplinary Symposium Music and Minorities</b>, the <b>Library under the Treetops</b> (several locations) and the street art <b>Creation Lab</b> at Gornji trg should be mentioned.
      <br>
      <br>
      The Festival will take place in any weather, as free raincoats will be available to visitors for a carefree enjoyment of the evening.
      <br>
      <br>
      You are cordially invited!`,

    //other
    time_filter: "time",
    location_filter: "venues",
    symposium_label: "Symposium",
    read_more: "Read more",
    more: "More",
    less: "Less",
    all: "ALL",
    all_locations: "ALL VENUES",
    back_to_all_locations: "BACK TO ALL VENUES",
    no_band: "No band data",
    no_program: "This years program is not available yet.",

    financer_disclaimer: `
      This project has been funded with support from the European Commission. 
      This web page reflects the views only of the author, and the Commission 
      cannot be held responsible for any use which may be made of the information contained therein.`,

    sipk_sponsors: "The investment is co-financed by the Republic of Slovenia and the European Union from the European Social Fund.",

    day_names: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    month_names: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],

    //media
    imago_desc: `
      The Imago Sloveniae Festival is an international project of great national importance. 
      Its festival cycles and cultural events have been present in Slovenia since 1988. 
      Imago Sloveniae Foundation and the Institute for the Revival of the Cultural Image 
      of the Old Town Center take care of the implementation of events within the festival. 
      Each year, more than 100 classical, jazz and ethno concerts are held within the festival, 
      attended by more than 80,000 visitors.`,

    media_contact: "Contact",
    phone: "Phone",
    maticna_stevilka: "Registration number",
    davcna_stevilka: "Tax number",
    davcni_zavezanec: "Taxpayer",
    yes: "YES",
    contact_person: "Press contact",

    donate_page: [
      "GIVE PART OF YOUR INCOME TAX (0.5%) TO THE IMAGO SLOVENIAE FOUNDATION",
      "Dear Sir or Madam, ",
      "Imago Sloveniae is one of the largest non-governmental cultural projects in Slovenia, which organizes ",
      "more than 100 concerts and other cultural events each year. ",
      "All our events are ",
      "free of charge for visitors, ",
      "thus we get all the resources we need from patrons, mostly donors and sponsors. ",
      "We are addressing you with the request that you dedicate 0.5% of your paid income tax, which would otherwise remain in the state budget, to our foundation. ",
      "The Imago Sloveniae Foundation is on the list of beneficiaries of personal income tax donations, in accordance with Article 142 of the Law on Personal Income Tax Z Doh-2 (Official Gazette RS, No. 117/06, 10/08, 78/08), which allows the taxpayer to claim 0.5% of personal income tax, which would otherwise remain in the state budget, earmarked for funding nonprofit organizations. ",
      "If you have decided to support our activities, we kindly ask you to fill out ",
      "the FORM (at this link) ",
      "and: ",
      "send to: Finančna urad, Davčna ulica 1, 1000 Ljubljana ",
      "return personally to our office at Gornji trg 16 in Ljubljana  ",
      "(Monday to Friday, 9am to 3pm) ",
      "or send by post to:  Ustanova Imago Sloveniae, Gornji trg 16, 1000 Ljubljana ",
      "You can also fill out the form on the public administration electronic services (E-Davki) portal if you have a qualified digital certificate. ",
      "The tax authority complies with the applicable forms, received by  ",
      "31 December 2016. ",
      "You can also support our operations by direct donation to our account at NLB, number ",
      "SI56 0205 1025 4910 271. ",
      "Thank you for your support! ",
      "Imago Sloveniae Foundation ",
      "Janoš Kern, Director "
    ]
  },
  sl: {
    //menu
    program: "PROGRAM",
    symposium: "SIMPOZIJ",
    symposium_about: "O SIMPOZIJU",
    symposium_program: "PROGRAM",
    other_events: "OSTALO DOGAJANJE",
    culinary: "KULINARIKA",
    other: "OSTALO",
    locations: "PRIZORIŠČA",
    about: "O FESTIVALU",
    media: "ZA MEDIJE",

    festival_name: "32. Festival Noči v stari Ljubljani",
    symposium_name: "Mednarodni večdisciplinarni simpozij: Zvok, pesem in političnost",
    project_partners_main: "Glavni pokrovitelji",
    project_partners_media: "Medijski pokrovitelji",
    project_partners_noci: "Noči v stari Ljubljani so podprli",

    //footer
    donate: "Prispevaj",
    sign_up: "Prijava",
    imago: "Ustanova Imago Sloveniae - Podoba Slovenije",
    contact: "KONTAKT",
    donate_five_percent: "DONIRAJTE 0.5% DOHODNINE",
    immediate_donation: "TAKOJŠNJA DONACIJA",
    sign_up_news: "PRIJAVA NA E-NOVICE",
    copyrights_reserved: "Vse pravice pridržane",

    //about festival
    about_festival: `
      Noči v stari Ljubljani, največji poulični festival v prestolnici, bo med 27. in 29. avgustom 2020 ponovno napolnil staro mestno jedro s kulturnim utripom. V teh kriznih časih predstavlja umetnost še zadnje zatočišče lepote in miru, zato je še toliko bolj pomembno, da se kulturno dogajanje odvija naprej. Na žalost smo bili zaradi finančnih omejitev prisiljeni letošnjo edicijo festivala precej zmanjšati. Noči v stari Ljubljani so se vsa leta financirale predvsem s sredstvi sponzorjev in koproducentov, ki pa jih je letos zaradi posledic koronavirusa bistveno težje pridobiti. Kljub težkim razmeram, nam je na Ustanovi Imago Sloveniae – Podoba Slovenije uspelo pripravili privlačen in kakovosten program. 
      Kultura je eno od področij, ki ga je aktualna kriza še posebej prizadela, zato smo se odločili, da letos podpremo predvsem domače umetnike. Tako bo moč prisluhniti nekaterim našim najboljšim izvajalcem in izbranim tujim glasbenikom, ki živijo v Sloveniji. Program pa bo začinilo tudi nekaj vrhunskih tujih zasedb.
      Vsi dogodki bodo tradicionalno za obiskovalce brezplačni, saj želimo kakovostne kulturne vsebine približati čim širšemu krogu someščanov in obiskovalcev Ljubljane.
      <br>
      <br>
      Tema 32. Noči v stari Ljubljani bo tradicijski zvoki sodobnosti. Vzporedno se bo prepletalo več programskih linij etno, jazz in klasične glasbe s poudarkom na glasbenih praksah, ki tradicijske vplive prelivajo v sodobne zvočne forme. Velik del programa bo vključeval manjšinske glasbenike, saj bo s programsko rdečo nitjo festivala tesno povezan tudi Mednarodni večdisciplinarni simpozij Glasba in manjšine, ki ga ob 20-letnici študijske skupine ICTM (Mednarodno združenje za tradicijsko glasbo) za glasbe manjšin, v času festivala pripravljamo v sodelovanju z Oddelkom za muzikologijo Filozofske fakultete Univerze v Ljubljani, Glasbenonarodopisnim inštitutom ZRC SAZU in KED Folk Slovenija.
      <br>
      <br>
      Noči v stari Ljubljani bodo tokrat postregle s 23 dogodki na 6 prizoriščih skrbno umeščenimi v čaroben poletni ambient starega mestnega jedra, na katerih bo nastopilo preko 100 glasbenikov iz 12 držav.
      <br>
      <br>
      Festival bo 27. avgusta 2020 ob 20. uri na osrednjem odru na Novem trgu otvorila mednarodna zasedba Mi Linda Dama (Italija, Srbija), vrhunski glasbeniški sestav, ki s sefardskimi zgodbami ustnega izročila odetimi v preplet andaluzijskih melodij in arabskih ritmov ustvarja bogato glasbeno–plesno izkušnjo.
      <br>
      <br>
      V nadaljevanju bodo Noči v stari Ljubljani postregle z udarnim mednarodno obarvanim programom. Prisluhnili bomo tangu v sodobni preobleki z mednarodnim projektom uveljavljenega harmonikarja Jureta Torija Tori Tango (Argentina, Avstrija, Slovenija), ljubljenec občinstva in vsestranski virtuoz na harmoniki Marko Hatlak bo nastopil s svojo Kapobando, mojstrica ljudskega petja Zvezdana Novaković pa se bo ob saksofonskem kvartetu Roya predstavila s projektom Slovena Voices (Hrvaška, Poljska, Slovenija).
      <br>
      <br>
      V duhu južnejših ritmov Andaluzije bo ob uveljavljeni flamenko plesalki Ani Pandur zaigral Vito Mareence Flamenco Duo, Mascara Quartet bodo očarali s čarobnostjo portugalskega fada, v družbi Lasanthi (Šrilanka, Indija, Slovenija) se obeta koncert indijske klasične glasbe, priča pa bomo tudi koncertu mednarodne zasedbe večkrat nagrajene inovatorke britanskega folka Hannah James The JigDoll Ensemble (Velika Britanija, Avstrija, Madžarska, Iran).
      <br>
      <br>
      Z repertoarjem navdanim z nostalgično toplino bo pokrajine časa slikala zasedba priznanega skladatelja Alda Kumarja Anbot, Nova Schola Labacensis bo navdušila z večerom grškega rebetika imenovanega tudi grški blues, prekaljeni skladatelj in violinist Matija Krečič, znan tudi kot soavtor mnogih Magnificovih uspešnic, pa bo premierno predstavil svojo novo ploščo Cut / Rez. Nastopili bodo še JUNEsHELEN & Alja Petric: Zvočne krajine - Spevi & Arije, Acamar Trio, JG3O in drugi.
      <br>
      <br>
      Poleg glasbenih dogodkov bodo Noči ponudile spremljevalni program, v okviru katerega velja poleg že omenjenega mednarodnega simpozija Glasba in manjšine omeniti Knjižnico pod krošnjami (več lokacij) in ulični Kreativni laboratorij na Gornjem trgu.
      <br>
      <br>
      Festival bo potekal tudi v primeru dežja, saj bodo za brezskrbno uživanje v večernem dogajanju obiskovalcem na voljo brezplačni dežni plašči.
      <br>
      <br>
      Prijazno vabljeni!`,

    //other
    time_filter: "čas",
    location_filter: "prizorišča",
    symposium_label: "Simpozij",
    read_more: "Preberi več",
    more: "Več",
    less: "Manj",
    all: "VSE",
    all_locations: "VSA PRIZORIŠČA",
    back_to_all_locations: "NAZAJ NA VSA PRIZORIŠČA",
    no_band: "Ni vpisane zasedbe",
    no_program: "Program za letošnje leto še ni vpisan.",

    financer_disclaimer: `
      Izvedba tega projekta je financirana s strani Evropske komisije. 
      Vsebina spletne strani je izključno odgovornost avtorja in v 
      nobenem primeru ne predstavlja stališč Evropske komisije.
      `,

    sipk_sponsors: "Naložbo sofinancirata Republika Slovenija in Evropska unija iz Evropskega socialnega sklada.",

    day_names: ["Nedelja", "Ponedeljek", "Torek", "Sreda", "Četrtek", "Petek", "Sobota"],
    month_names: [
      "januar",
      "februar",
      "marec",
      "april",
      "maj",
      "junij",
      "julij",
      "avgust",
      "september",
      "oktober",
      "november",
      "december"
    ],

    //media
    imago_desc: `
      Festival Imago Sloveniae je mednarodni projekt nacionalnega pomena. 
      Festivalski cikli in kulturna ponudba festivala so v slovenskem prostoru prisotni od leta 1988. 
      Za izvedbo dogodkov v okviru festivala skrbita Ustanova Imago Sloveniae - Podoba Slovenije in 
      Zavod za oživljanje kulturne podobe starega mestnega jedra. Vsako leto se v okviru festivala zvrsti 
      več kot 100 koncertov klasične, jazz in etno glasbe, ki se jih udeleži več kot 80.000 obiskovalcev.`,

    media_contact: "Kontakt",
    phone: "Tel.",
    maticna_stevilka: "Matična številka",
    davcna_stevilka: "Davčna številka",
    davcni_zavezanec: "Davčni zavezanec",
    yes: "YES",
    contact_person: "Kontaktna oseba za medije",

    donate_page: [
      "NAMENITE DEL VAŠE DOHODNINE (0,5%) USTANOVI IMAGO SLOVENIAE",
      "Spoštovani, ",
      "Imago Sloveniae – Podoba Sloveniae je po obsegu eden največjih nevladnih kulturnih projektov v Sloveniji, vokviru katerega se vsako leto zvrsti ",
      "več kot 100 koncertov in drugih kulturnih dogodkov. ",
      "Vse naše prireditve so ",
      "brezplačne za obiskovalce, ",
      "zato moramo vsa potrebna sredstva pridobiti od pokroviteljev in sicer večinoma od donatorjev in sponzorjev. ",
      "Na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem proračunu, namenite naši Ustanovi. ",
      "Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev do donacij iz dohodnine, v skladu s 142. členom Zakona o dohodnini Z Doh-2 (Uradni list RS, št. 117/06, 10/08, 78/08), ki omogoča, da davčni zavezanec zahteva, da se 0,5 % dohodnine, ki bi sicer ostala v državnem proračunu, nameni za financiranje neprofitnih organizacij. ",
      "V kolikor ste se odločili, da podprete naše delovanje, vas vljudno prosimo, da izpolnite ",
      "OBRAZEC (na tej povezavi) ",
      "in ga: ",
      "pošljete na naslov: Finančna urad, Davčna ulica 1, 1000 Ljubljana ",
      "vrnete osebno v našo pisarno na Gornjem trgu 16 v Ljubljani ",
      "(od ponedeljka do petka od 9.00 do 15.00 ure) ",
      "ali pošljete po pošti na naslov: Ustanova Imago Sloveniae, Gornji trg 16, 1000 Ljubljana ",
      "Obrazec lahko izpolnite tudi na portalu elektronske storitve javne uprave (E-Davki) v primeru, da imate kvalificirano digitalno potrdilo. ",
      "Davčni organ upošteva veljavne zahteve, katere prejme do ",
      "31. decembra 2016. ",
      "Naše delovanje lahko podprete tudi z direktno donacijo na naš račun pri NLB številka ",
      "SI56 0205 1025 4910 271. ",
      "Za izkazano podporo se vam iskreno zahvaljujemo! ",
      "Ustanova Imago Sloveniae ",
      "Janoš Kern, direktor "
    ]
  }
};

export { translations };
