import React, { useContext, useState, useRef } from "react";
import { appState } from "../App";
import { getTranslatedString, getHourString, getDayString, getCountry, getArtistLargeImage } from "../config/helperFunctions.js";
import { translations } from "../config/translations.js";

import "./styles/ArtistPage.css";

//const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop-100); // General scroll to element function

const ArtistPage = props => {
  const state = useContext(appState);
  const artistsList = state.events;
  const locationsList = state.locations;
  const festivalsList = state.festivals;
  const language = state.language;

  let title = "";
  let desc = "";
  let currentArtist = null;
  let day, time;
  let artistFestivals = [];
  let country;
  let locationName;
  let image;

  let band;
  let description;

  const [descExpanded, setDescExpanded] = useState(false);

  const onExpandClick = expanded => {
    setDescExpanded(expanded);
    //executeScroll();
  };

  const myRef = useRef(null);
  //const executeScroll = () => scrollToRef(myRef);

  let artistView;

  if (artistsList !== null) {
    let targetId = props.match.params.id;

    let arrayLength = artistsList.length;
    for (let i = 0; i < arrayLength; i++) {
      currentArtist = artistsList[i];
      if (targetId === artistsList[i].id) break;
    }

    title = getTranslatedString(currentArtist.title, language);
    desc = getTranslatedString(currentArtist.description, language);

    time = getHourString(currentArtist.datetime, language);
    day = getDayString(currentArtist.datetime, language, true);

    let venueId = currentArtist.venueId;

    arrayLength = locationsList.length;
    for (let i = 0; i < arrayLength; i++) {
      if (venueId === locationsList[i].id) {
        locationName = getTranslatedString(locationsList[i].title, language);
        break;
      }
    }

    title = getTranslatedString(currentArtist.title, language);

    country = getCountry(title);
    title = title.replace(country, "");
    image = getArtistLargeImage(currentArtist);

    if (country !== undefined) country = country.replace(/[()]/g, "");

    artistFestivals = [];
    arrayLength = festivalsList.length;
    for (let i = 0; i < currentArtist.festivalIds.length; i++) {
      for (let j = 0; j < arrayLength; j++) {
        if (currentArtist.festivalIds[i] === festivalsList[j].id) artistFestivals.push(getTranslatedString(festivalsList[j].title, language));
      }
    }

    desc = getTranslatedString(currentArtist.description, language);

    band = desc
      .substring(0, desc.indexOf("<hr"))
      .trim()
      .replace(/(\r\n|\n|\r)/gm, "<br/>");

    if (band.includes("<strong"))
      desc = desc
        .substring(desc.indexOf("<hr />") + 6, desc.length)
        .trim()
        .replace(/(\r\n|\n|\r)/gm, "<br/>")
        .replace("<br/><br/>", "<br/>");
    else {
      band = translations[language].no_band;
      desc = desc
        .trim()
        .replace(/(\r\n|\n|\r)/gm, "<br/>")
        .replace("<br/><br/>", "<br/>");
      if (desc.indexOf("<hr />") === 0) desc = desc.substring(6, desc.length);
    }

    let paragraphs = desc.split("<hr />");
    let wordDescription;
    let maxWords = 0;
    paragraphs.forEach((item, i) => {
      if (maxWords < item.length) {
        wordDescription = item;
        maxWords = item.length;
      }
    });

    desc = desc.replace(wordDescription, "<br/>_description_<br/>");
    paragraphs = desc.split("<br/>");
    if (paragraphs[0] === "") paragraphs.shift();
    let descPara = wordDescription.split("<br/>");
    descPara = descPara.filter(function(e) {
      return e;
    });

    let expandableDesc = true;
    let descExpandedOverride = false;
    let charCountOfDesc = 0;
    descPara.forEach(item => {
      charCountOfDesc += item.length;
    });
    if (charCountOfDesc < 800) {
      descExpandedOverride = true;
      expandableDesc = false;
    }

    if (descPara.length < 3) expandableDesc = false;

    description = (
      <div className="artist-description">
        {paragraphs.map((item, index) => {
          let descDiv = [];
          if (item === "") return <div key={item + index} className="artist-desc-spacer" />;
          else if (item.includes("<hr")) return <div key={item + index} className="artist-desc-line" />;
          else if (item === "_description_") {
            if (descExpanded === true || descExpandedOverride) {
              for (let i = 0; i < descPara.length; i++) {
                const element = descPara[i];
                if (element !== "") {
                  descDiv.push(<div className="desc-item" key={element + i} dangerouslySetInnerHTML={{ __html: element }} />);
                }
              }
            } else {
              const element = descPara[0];
              if (element !== "" && !element.includes("<img")) {
                descDiv.push(<div className="desc-item" key={"desc-item" + 0} dangerouslySetInnerHTML={{ __html: element }} />);
              }
            }
            descDiv.push(
              <div className="desc-expand" key="read-more" onClick={() => onExpandClick(!descExpanded)}>
                {expandableDesc ? (
                  <i>
                    <b>{descExpanded ? translations[language].less : translations[language].more} ></b>
                  </i>
                ) : (
                  ""
                )}
              </div>
            );
            return (
              <div className="artist-page-desc" key={item + index} ref={myRef}>
                {descDiv}
              </div>
            );
          } else return <div className="artist-data-item" key={item} dangerouslySetInnerHTML={{ __html: item }} />;
        })}
      </div>
    );

    let w = window.innerWidth;
    if (w < 500) {
      artistView = (
        <div>
          <div className="artist-page-title">{title}</div>
          <div className="artist-page-country">{country}</div>
          <div className="content-container">
            <div className="artist-description-container">
              <img src={image} alt="" />
              <div className="artist-data">
                <div className="location-time">
                  {day} - {time}
                  <br />
                  <div className="artist-data-location">{locationName}</div>
                </div>
                <div className="members" dangerouslySetInnerHTML={{ __html: band }} />
              </div>
            </div>
            <div className="artist-long-description normal-text">{description}</div>
          </div>
        </div>
      );
    } else {
      artistView = (
        <div>
          <div className="artist-page-title">{title}</div>
          <div className="artist-page-country">{country}</div>
          <div className="content-container">
            <div className="artist-description-container">
              <img src={image} alt="" />
              <div className="artist-long-description normal-text">{description}</div>
            </div>
            <div className="artist-data">
              <div className="location-time">
                {day} <br />
                {time}
                <br />
                <div className="artist-data-location">{locationName}</div>
              </div>
              <div className="members" dangerouslySetInnerHTML={{ __html: band }} />
            </div>
          </div>
        </div>
      );
    }
  } else {
    artistView = "";
  }

  return <div className="main-container">{artistView}</div>;
};

export default ArtistPage;
