import React, {useContext} from "react";
// import SimpleButton from "../components/SimpleButton";
import { translations } from "../config/translations.js";
import { appState } from "../App";
// import NewsComponent from "../components/NewsComponent";
// import { Link } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/Media.css";

const Media = () => {
  const state = useContext(appState);
  const language = state.language;
  return (
    <div className="main-container">
      <div className="about-festival">
        {translations[language].imago_desc} <a href="https://imagosloveniae.net">{translations[language].more}></a>
      </div>
      <div className="publications">
        {/* <div className="title">Objave za medije</div>
        <NewsComponent
          date="15. 01. 2019"
          title="Namenite del vaše dohodnine (0,5 %) Ustanovi Imago Sloveniae – Podoba Slovenije"
          content="Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem
              proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev
              do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine."
        />
        <NewsComponent
          date="12. 12. 2018"
          title="Posnetek otvoritvenega koncerta 30. Mednarodnega festivala Noči v stari Ljubljani, 3. del"
          content="Vljudno vas vabimo k ogledu posnetka zadnjega dela otvoritvenega koncerta 30. Mednarodnega festivala Noči
            v Stari Ljubljani, kjer smo gostili izjemno skupino glasbenih raziskovalcev in ustvarjalcev iz Argentine,
            združenih v Orkester tradicijskih glasbil in novih tehnologij. Posnetek si lahko ogledate v Arhivu oddaj
            RTV Slovenija na tej povezavi."
        />
        <Link to="/all_news">
          <SimpleButton text="Več objav" />
        </Link>
        <div className="title">Izjave za medije</div>
        <div className="statement-container">
          <img src="/images/download.svg" alt="" />
          <div>19.5.2019</div>
        </div>
        <div className="statement-container">
          <img src="/images/download.svg" alt="" />
          <div>15.4.2019</div>
        </div>
        <div className="statement-container">
          <img src="/images/download.svg" alt="" />
          <div>1.2.2019</div>
        </div>
        <div className="title">Logotipi</div>
        <div className="logo-desc">Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine, ki bi sicer</div>
        <img src="/images/download.svg" alt="" />
        <div className="title">Fotogradivo</div>
        <div className="logo-desc">Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine, ki bi sicer</div>
        <img src="/images/download.svg" alt="" /> */}
        <div className="title">{translations[language].media_contact}</div>
        {translations[language].imago}
        <br />
        Gornji trg 16
        <br />
        1000 Ljubljana
        <br />
        <br />
        {translations[language].phone}: +386 01 241 66 00
        <br />
        E-mail: imago@siol.net
        <br />
        www.imagosloveniae.net
        <br />
        <br />
        {translations[language].maticna_stevilka}: 1191292000
        <br />
        {translations[language].davcna_stevilka}: 16486919
        <br />
        {translations[language].davcni_zavezanec}: {translations[language].yes}
        <div className="title">{translations[language].contact_person}</div>
        {translations[language].imago}
        <br />
        Gornji trg 16
        <br />
        1000 Ljubljana
        <br />
        <br />
        {translations[language].phone}: +386 01 241 66 00
        <br />
        E-mail: imago@siol.net
        <br />
        www.imagosloveniae.net
      </div>
    </div>
  );
};

export default Media;
