import React, { useContext } from "react";
import { translations } from "../config/translations.js";
import { getDayString } from "../config/helperFunctions";
import { appState } from "../App";
import "./styles/DatePicker.css";


const DatePicker = props => {
  let state = useContext(appState);
  let language = state.language;

  const onDateChanged = (key, date) => {
    props.onDate(key, date);
  };

  const generateLayout = () => {
    let dates = props.dates;
    let date = new Date();
    let dateString = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

    if (props.currentDate === "")
      if (dates[dateString] !== undefined) props.onDate(dateString, dates[dateString].date);
      else props.onDate(Object.keys(props.dates)[0], props.dates[Object.keys(props.dates)[0]].date);

    return Object.keys(dates).map((key, i) => {
      let currentDateInObject = dates[key].date;
      let toDisplay = null;

      if (currentDateInObject instanceof Date) {
        if (window.innerWidth > 1050) {
          let dateStringFormat = "";
          if (language === "en") {
            switch (currentDateInObject.getDate()) {
              case 1:
                dateStringFormat = "st";
                break;
              case 2:
                dateStringFormat = "nd";
                break;
              default:
                dateStringFormat = "th";
                break;
            }
          } else {
            dateStringFormat = ".";
          }

          toDisplay = (
            <div>
              <b>{translations[language].day_names[currentDateInObject.getDay()].toUpperCase()} </b>
              {currentDateInObject.getDate() +
                dateStringFormat +
                " " +
                translations[language].month_names[currentDateInObject.getMonth()]}
            </div>
          );
        } else
          toDisplay = (
            <div>
              <b>{translations[language].day_names[currentDateInObject.getDay()].toUpperCase().substring(0, 3)} </b>
              {getDayString(currentDateInObject, language, false)}
            </div>
          );
      } else if (props.displayAll) {
        toDisplay = (
          <div>
            <b>{translations[language].all}</b>
          </div>
        );
      }

      if (toDisplay === null) return null;
      else {
        let datesEqual = props.currentDate === currentDateInObject || props.currentDate - currentDateInObject === 0;
        return (
          <div
            className={"date-item " + (datesEqual ? "selected-date-item" : "")}
            key={"date" + i}
            onClick={() => onDateChanged(key, currentDateInObject)}
          >
            {toDisplay}
          </div>
        );
      }
    });
  };

  return <div className="date-picker-container">{props.dates !== null ? generateLayout() : ""}</div>;
};

export default DatePicker;
