import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";
import App from "./App";
import * as serviceWorker from "./config/serviceWorker";

ReactDOM.render(<Router><App /></Router>, document.getElementById("root"));

serviceWorker.register();
