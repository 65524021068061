import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const getTranslatedString = (array, language) => {
  let title;

  if (array[language] !== undefined) title = array[language];
  else if (array.en !== undefined) title = array.en;
  else if (array.sl !== undefined) title = array.sl;
  else title = array[Object.keys(array)[0]];

  return title;
};

const getShortenedString = (oldString, maxLength) => {

  oldString = stripHtml(oldString.replace(/<br\s*\/?>/mg," ")).trim();

  if (oldString.length < maxLength)
    return oldString + " ";

  let words = oldString.split(" ");
  let newString = "";
  let newStringLength = 0;

  let arLength = words.length;
  for (let i = 0; i < arLength; i++) {
    if (newStringLength > maxLength) {
      break;
    } else {
      newString += words[i] + " ";
      newStringLength += words[i].length;
    }
  }

  return newString + "... ";
};

const getArtistLargeImage = artistData => {
  if (artistData.image === null) return "";
  else if (artistData.image.medium !== undefined) return artistData.image.medium;
  else if (artistData.image.large !== undefined) return artistData.image.large;
  else if (artistData.image.full !== undefined) return artistData.image.full;
  else return "";
};

const getArtistSquareImage = artistData => {
  if (artistData.image === null) return "";
  else if (artistData.image.square !== undefined) return artistData.image.square;
  else if (artistData.image.full !== undefined) return artistData.image.full;
  else return "";
}

const getDayString = (datetime, language, includeYear) => {
  var date = new Date(datetime);

  let options = includeYear
    ? {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }
    : {
        month: "numeric",
        day: "numeric"
      };

  return date.toLocaleDateString(language, options); //en is language option, you may specify..
};

const getHourString = (datetime, language) => {
  let date = new Date(datetime);
  return date.toLocaleTimeString(language, { hour: "2-digit", minute: "2-digit" });
};

const getCountry = title => {
  let matchEmail = /\(([^)]*)\)[^(]*$/g;
  let found = title.match(matchEmail);
  if (found) return found[0];
};

const stripHtml = html => {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export { getTranslatedString, getShortenedString, getDayString, getHourString, getArtistLargeImage, getArtistSquareImage, getCountry, stripHtml };
