import React, { useContext, useState } from "react";
import { getTranslatedString } from "../config/helperFunctions";
import DatePicker from "../components/DatePicker";
import SimposiumItem from "../components/SimposiumItem";
import { appState } from "../App";
import Loader from "../components/Loader";

import "./styles/SimposiumProgram.css";
import { translations } from "../config/translations";

let currentDate = "";
let currentKey = "";

let programToDisplay = "";

const SimposiumProgram = () => {
  const state = useContext(appState);
  const language = state.language;
  const symposiumProgram = state.symposiumProgram;
  let contentDiv = <Loader key="loader"></Loader>;

  let symposiumTitle = "";

  let symposiumSchedule = "";
  if (symposiumProgram !== null && symposiumProgram !== undefined) {
    symposiumSchedule = getTranslatedString(symposiumProgram.description, language).replace("<hr />", "");
    symposiumTitle = getTranslatedString(symposiumProgram.title, language);
  }
  symposiumSchedule = symposiumSchedule.substring(symposiumSchedule.indexOf("</h3>") + 5, symposiumSchedule.length);
  let scheduleFormated = symposiumSchedule.substring(symposiumSchedule.indexOf("<h3>"), symposiumSchedule.length);
  // eslint-disable-next-line
  const [update, setUpdate] = useState(null);

  const onDateSelect = (key, date) => {
    currentDate = date;
    currentKey = key;
    setUpdate(date);
  };

  const getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  };

  let programDailyDiv = [];

  let wholeProgram = scheduleFormated.split("<h3>");
  let symposiumAddress = wholeProgram[1];
  wholeProgram.shift();
  wholeProgram.shift();

  let datesProgram = [];
  let dates = [];

  wholeProgram.forEach(dailyProgram => {
    programDailyDiv = [];
    let endOfDateString = "</h3>";
    let endOfDateIndex = dailyProgram.indexOf(endOfDateString);
    let date = dailyProgram.substring(0, endOfDateIndex);
    dailyProgram = dailyProgram.substring(endOfDateIndex + endOfDateString.length, dailyProgram.length);

    let dailyEvents = dailyProgram.split("<strong>");
    dailyEvents.shift();

    let dateString = date
      .substring(date.indexOf(",") + 1, date.length)
      .replace(/ /g, "")
      .trim();
    let dateInfo = dateString.split(".");
    let d = new Date(dateInfo[2] + "/" + dateInfo[1] + "/" + dateInfo[0]);

    dailyEvents.forEach(event => {
      let endOfEventString = "</strong>";
      let endOfEventIndex = event.indexOf(endOfEventString);
      let eventTitle = event.substring(0, endOfEventIndex);

      event = event.substring(endOfEventIndex + endOfEventString.length, event.length);

      let eventHour = eventTitle.substring(0, getPosition(eventTitle, "-", 1)).trim();
      eventTitle = eventTitle.substring(getPosition(eventTitle, "-", 1) + 2, eventTitle.length).trim();

      programDailyDiv.push(<SimposiumItem key={event + eventHour} title={eventTitle} hour={eventHour} content={event === "&nbsp;" ? null : event} />);
    });
    datesProgram[dateString] = programDailyDiv;
    dates[dateString] = { date: d, events: null };
  });

  programToDisplay = datesProgram[currentKey];

  if (symposiumProgram !== null && symposiumProgram !== undefined)
    contentDiv = (
      <div>
        <div className="title margin-on-mobile">{symposiumTitle}</div>
        <div className="title subtitle-symposium margin-on-mobile">{symposiumAddress.substring(0, symposiumAddress.length - 7)}</div>
        <div className="symposium-date-picker">
          <DatePicker onDate={onDateSelect} dates={dates} currentDate={currentDate} />
        </div>
        <div className="margin-on-mobile">
          <div className="symposium-program-container">{programToDisplay}</div>
        </div>
      </div>
    );
  else if (symposiumProgram === undefined) contentDiv = <div className="margin-on-mobile">{translations[language].no_program}</div>;

  return <div className="main-container-mobile">{contentDiv}</div>;
};

export default SimposiumProgram;
