import React, { useContext } from "react";
import { appState } from "../App";
import { translations } from "../config/translations.js";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const Donate = () => {
  let state = useContext(appState);
  let language = state.language;

  let paragrahCounter = 0;
  return (
    <div className="main-container">
      <div className="title">{translations[language].donate_page[paragrahCounter++]}</div>
      <div className="normal-text">
        {translations[language].donate_page[paragrahCounter++]}
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <b>{translations[language].donate_page[paragrahCounter++]}</b>
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <b>{translations[language].donate_page[paragrahCounter++]}</b>
        {translations[language].donate_page[paragrahCounter++]}
        <br />
        <br />
        <b>{translations[language].donate_page[paragrahCounter++]}</b>
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <a href="https://imagosloveniae.net/wp-content/uploads/2013/12/Zahtevek-za-donacijo-dela-dohodnine.pdf">{translations[language].donate_page[paragrahCounter++]}</a>
        {translations[language].donate_page[paragrahCounter++]}
        <ul>
          <li>
            <u>{translations[language].donate_page[paragrahCounter++]}</u>
          </li>
          <li>
            <u>{translations[language].donate_page[paragrahCounter++]} </u>
            {translations[language].donate_page[paragrahCounter++]}
          </li>
          <li>
            <u>{translations[language].donate_page[paragrahCounter++]} </u>
          </li>
        </ul>
        {translations[language].donate_page[paragrahCounter++]}
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <b>{translations[language].donate_page[paragrahCounter++]}</b>
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <b>{translations[language].donate_page[paragrahCounter++]}</b>
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <br />
        <br />
        {translations[language].donate_page[paragrahCounter++]}
        <br />
        {translations[language].donate_page[paragrahCounter++]}
      </div>
    </div>
  );
};

export default Donate;
