import React, { useContext, useState } from "react";
import { appState } from "../App";
import { getTranslatedString } from "../config/helperFunctions.js";
import ArtistGrid from "../components/ArtistGrid";
import DatePicker from "../components/DatePicker";
import Loader from "../components/Loader";
import FilterToggle from "../components/FilterToggle";

import "../components/styles/ArtistGrid.css";
import { translations } from "../config/translations";

let currentArtistsListKey = "";
let currentDate = "";

const Program = () => {
  let artistGrid = [];

  const state = useContext(appState);
  const artistsList = state.events;
  const locationsList = state.locations;
  const eventDays = state.eventDays;
  const language = state.language;

  let currentArtistsList = null;

  // eslint-disable-next-line
  const [update, setUpdate] = useState(null);
  let noProgram = false;

  const onDateSelect = (key, date) => {
    currentArtistsListKey = key;
    currentDate = date;
    setUpdate(date);
  };

  const onFilterToggle = toggle => {
    state.isTime = toggle;
    setUpdate(toggle);
  };

  if (locationsList !== null) {
    if (eventDays[currentArtistsListKey] !== undefined) currentArtistsList = eventDays[currentArtistsListKey].events;
    else currentArtistsList = eventDays[Object.keys(eventDays)[0]].events;

    if (Object.keys(eventDays)[0] === "-") noProgram = true;
    let allEventsSelected = false;
    if (currentArtistsList === undefined) {
      allEventsSelected = true;
      currentArtistsList = artistsList;
    }

    let allLocationsAndEvents = [];
    let locationName;
    let locationId;
    let artistsOnThatLocation;
    if (!state.isTime) {
      locationsList.forEach((location, i) => {
        locationName = getTranslatedString(location.title, language).toUpperCase();
        locationId = location.id;
        artistsOnThatLocation = [];

        currentArtistsList.forEach(artist => {
          if (artist.venueId === locationId) {
            artist.locationName = locationName;
            artistsOnThatLocation.push(artist);
          }
        });

        if (artistsOnThatLocation.length !== 0) allLocationsAndEvents.push(artistsOnThatLocation);
      });
      allLocationsAndEvents.sort(function(a, b) {
        let nameA = a[0].locationName.toUpperCase();
        let nameB = b[0].locationName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      for (let i = 0; i < allLocationsAndEvents.length; i++) {
        const element = allLocationsAndEvents[i];
        artistGrid.push(
          <ArtistGrid
            key={i}
            language={language}
            artistsList={element}
            locationsList={locationsList}
            symposium={false}
            displayLocation={element[0].locationName}
            displayDates={allEventsSelected ? true : false}
          />
        );
      }
    } else {
      artistGrid.push(
        <ArtistGrid
          key="wholeGrid"
          language={language}
          artistsList={currentArtistsList}
          locationsList={locationsList}
          symposium={false}
          displayLocation={null}
          displayDates={allEventsSelected ? true : false}
        />
      );
    }
  }

  let programDiv;
  let w = window.innerWidth;
  if (w < 500) {
    programDiv = (
      <div className="main-container-mobile">
        {locationsList !== null ? (
          <div>
            <DatePicker dates={eventDays} onDate={onDateSelect} currentDate={currentDate} displayAll={true} />
            <FilterToggle onToggle={onFilterToggle} language={language} />
            {artistGrid}
          </div>
        ) : (
          <Loader key="loader" />
        )}
      </div>
    );
  } else {
    programDiv = (
      <div className="main-container">
        {locationsList !== null ? (
          <div>
            <FilterToggle onToggle={onFilterToggle} language={language} />
            <DatePicker dates={eventDays} onDate={onDateSelect} currentDate={currentDate} displayAll={true} />
            {artistGrid}
          </div>
        ) : (
          <Loader key="loader" />
        )}
      </div>
    );
  }
  if (noProgram) return <div className="main-container"><div>{translations[language].no_program}</div></div>;
  else return programDiv;
};

export default Program;
