import React from "react";
import "./styles/SimpleButton.css";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const SimpleButton = (props) => {
  return (
    <div className="button-container" onClick={props.onSelect !== undefined ? () => props.onSelect() : void 0}>
      <span>{props.text}</span>
    </div>
  );
};

export default SimpleButton;
