import React, { useContext } from "react";
import { appState } from "../App";
import { getTranslatedString } from "../config/helperFunctions";
import Loader from "../components/Loader";

import { translations } from "../config/translations";

const Simposium = () => {
  const state = useContext(appState);
  const language = state.language;
  const symposiumProgram = state.symposiumProgram;
  let contentDiv = <Loader key="loader"></Loader>;
  let nociSponsorsGrid = [];

  let symposiumAbout = "";
  let symposiumTitle = "";
  if (symposiumProgram !== null && symposiumProgram !== undefined) {
    symposiumAbout = getTranslatedString(symposiumProgram.description, language);
    symposiumTitle = getTranslatedString(symposiumProgram.title, language);

    symposiumAbout = symposiumAbout.substring(symposiumAbout.indexOf("</h3>") + 5);
    symposiumAbout = symposiumAbout
      .substring(0, symposiumAbout.indexOf("<h3>"))
      .trim()
      .replace(/(\r\n|\n|\r)/gm, "<br/>");

    contentDiv = (
      <div>
        <div className="title">{symposiumTitle}</div>
        <div className="normal-text" dangerouslySetInnerHTML={{ __html: symposiumAbout }}></div>
        <div className="sponsors-container symposium-sponsors-container">{nociSponsorsGrid}</div>
      </div>
    );
  } else if (symposiumProgram === undefined) {
    contentDiv = <div>{translations[language].no_program}</div>;
  }

  let req = require.context("../../public/images/pokrovitelji/simpozij", false, /.*\.png$/);
  req.keys().forEach(function(key) {
    nociSponsorsGrid.push(
      <div className="sponsors-image-container" key={key}>
        <img src={"/images/pokrovitelji/simpozij/" + key.substring(2, key.length)} alt="" />
      </div>
    );
  });

  return <div className="main-container">{contentDiv}</div>;
};

export default Simposium;
