import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { appState } from "../App";
import Dotdotdot from "react-clamp";
import { getTranslatedString, getShortenedString, getDayString, getHourString, getArtistSquareImage } from "../config/helperFunctions.js";

import "./styles/ArtistSquare.css";

const ArtistSquare = props => {
  let name;
  let id;
  let time;
  let location;
  let image;
  let eventPassed = false;

  const state = useContext(appState);
  let locationsList = state.locations;
  let language = state.language;
  let isIeOrEdge = state.isIeOrEdge;

  eventPassed = false;
  name = getTranslatedString(props.artist.title, language);

  image = getArtistSquareImage(props.artist);
  id = props.artist.id;
  let date = new Date(props.artist.datetime);
  if (props.displayDates === true) time = getDayString(props.artist.datetime, language, false);
  else time = getHourString(props.artist.datetime, language);

  if (date < new Date()) eventPassed = true;

  let arrayLength = locationsList.length;
  for (let index = 0; index < arrayLength; index++) {
    if (locationsList[index].id === props.artist.venueId) {
      location = getTranslatedString(locationsList[index].title, language);
      break;
    }
  }

  let w = window.innerWidth;
  let maxLengthName = 30;
  if (name.length > maxLengthName && w > 500) name = getShortenedString(name, maxLengthName);

  if (name.includes("AND RITUAL")) name = name.replace("AND RITUAL", "AND");

  let imageDiv;
  if (isIeOrEdge) {
    if (w < 500) {
      imageDiv = (
        <div className={"artist-square-container " + (eventPassed ? "passed-container" : "")} onClick={() => props.onSelect(id)}>
          <div className="mobile-artist-desc ie-desc">
            <div className="mobile-artist-date">{time}</div>
            <div className="mobile-artist-name-container">
              <div>
                <Dotdotdot clamp={2} className="two-line-text-clamp">
                  {name}
                </Dotdotdot>
              </div>
              <div className="mobile-artist-location">
                <img src="/images/location-pin-mobile.png" alt="" />
                <span>
                  {w < 400 ? (
                    <Dotdotdot clamp={1} className="one-line-text-clamp">
                      {location}
                    </Dotdotdot>
                  ) : (
                    <Dotdotdot clamp={2} className="two-line-text-clamp">
                      {location}
                    </Dotdotdot>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      imageDiv = (
        <div className="artist-square-container" onClick={() => props.onSelect(id)}>
          <div className={"overlay-ie-edge " + (props.selected ? "overlay-ie-edge-selected " : "") + (eventPassed ? "overlay-ie-edge-passed" : "")} />
          <img className={"filter-img-ie-edge " + (props.selected ? "selected-filter-img-ie-edge " : " ")} alt="" src={image} />
          <div className="artist-desc-ie-edge">
            <div className="artist-time-location">
              <span>{time}</span>
              <br />
              <b>{location}</b>
            </div>
            <div className="artist-title">{name}</div>
          </div>
        </div>
      );
    }
  } else {
    if (w < 500) {
      imageDiv = (
        <div className={"artist-square-container " + (eventPassed ? "passed-container" : "")} onClick={() => props.onSelect(id)}>
          {image !== undefined ? (
            <div>
              <img className={"filter-img "} alt="" src={image} />
              <img className="no-filter-img" alt="" src={image} />
            </div>
          ) : (
            <div />
          )}
          <div className="mobile-artist-desc">
            <div className="mobile-artist-date">{time}</div>
            <div className="mobile-artist-name-container">
              <div>
                <Dotdotdot clamp={2} className="two-line-text-clamp">
                  {name}
                </Dotdotdot>
              </div>
              <div className="mobile-artist-location">
                <img src="/images/location-pin-mobile.png" alt="" />
                <span>
                  {w < 400 ? (
                    <Dotdotdot clamp={1} className="one-line-text-clamp">
                      {location}
                    </Dotdotdot>
                  ) : (
                    <Dotdotdot clamp={2} className="two-line-text-clamp">
                      {location}
                    </Dotdotdot>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      imageDiv = (
        <div className={"artist-square-container " + (eventPassed ? "passed-container" : "") + " " + (props.selected ? "selected-container" : "")} onClick={() => props.onSelect(id)}>
          {image !== undefined ? (
            <div>
              <img className={"filter-img " + (props.selected ? "selected-filter-img " : " ")} alt="" src={image} />
              <img className="no-filter-img" alt="" src={image} />
            </div>
          ) : (
            <div />
          )}
          <div className={"artist-desc "}>
            <div className="artist-time-location">
              <span>{time}</span>
              <br />
              <b>{location}</b>
            </div>
            <div className="artist-title" dangerouslySetInnerHTML={{ __html: name }}></div>
          </div>
        </div>
      );
    }
  }

  if (name.toLowerCase().includes("simpozij") || name.toLowerCase().includes("symposium")) {
    return <Link to="/symposium_program">{imageDiv}</Link>;
  } else return imageDiv;
};

export default ArtistSquare;
