import React, { Component } from "react";
import { Route, NavLink, Link, withRouter } from "react-router-dom";
import { translations } from "./config/translations.js";
import { menuItemsObject, homepage } from "./config/header.js";

import ScrollToTop from "./components/ScrollToTop.js";
import Artist from "./pages/ArtistPage";
import Donate from "./pages/Donate";
import OneNews from "./pages/OneNews";
import AllNews from "./pages/AllNews";
import Footer from "./components/Footer";
import Media from "./pages/Media";

import "./App.css";

let defaultLanguage = "sl";
let scrollTreshold = 50;

let artists = null;
let locations = null;
let festivals = null;
let eventDays = null;

const state = {
  language: defaultLanguage,
  events: artists,
  eventDays: eventDays,
  locations: locations,
  festivals: festivals,
  symposiumProgram: null,
  culinaryEvents: null,
  isTime: true,
  programDate: null,
  isIeOrEdge: false
};

const appState = React.createContext(state);

/*
FOUR BREAKPOINTS
1200px, 1050px, 750px, 500px
*/

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { logoHidden: true, mobileMenuOpened: false, subMenuOpened: null };
  }

  componentDidMount() {
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    if (isIE || isEdge) state.isIeOrEdge = true;

    let currentYear = new Date().getFullYear();
    currentYear = 2020;
    let festivalsSlug = "noci-v-stari-ljubljani";

    Promise.all([
      fetch("https://api.imagosloveniae.net/v1/festivals/" + festivalsSlug + "/" + currentYear),
      fetch("https://api.imagosloveniae.net/v1/festivals"),
      fetch("https://api.imagosloveniae.net/v1/festivals/" + festivalsSlug + "/" + currentYear + "/symposiums"),
      fetch("https://api.imagosloveniae.net/v1/festivals/" + festivalsSlug + "/" + currentYear + "/culinary-events")
    ])
      .then(([res1, res2, res3, res4]) => Promise.all([res1.json(), res2.json(), res3.json(), res4.json()]))
      .then(([data1, data2, data3, data4]) => {
        state.events = data1.events;
        state.locations = data1.venues;
        state.festivals = data2;
        state.symposiumProgram = data3[0];
        state.culinaryEvents = data4[0];
        state.events.sort(function (a, b) {
          return new Date(a.datetime) - new Date(b.datetime);
        });
        let allFestivalDates = [];
        let eventsFiltered = [];

        state.events.forEach((item) => {
          const hasNightsInTitle = item.title.sl.includes("NOČI V STARI");
          if (item.title.sl !== undefined && (!hasNightsInTitle || (hasNightsInTitle && item.title.sl.includes("OTVORITEV")))) {
            eventsFiltered.push(item);

            if (item.image !== null) {
              new Image().src = item.image.large;
              new Image().src = item.image.medium;
            }

            let date = new Date(item.datetime);
            let dateString = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
            if (allFestivalDates[dateString] === undefined) allFestivalDates[dateString] = { date: date, events: [item] };
            else allFestivalDates[dateString].events.push(item);
          }
        });

        state.events = eventsFiltered;

        allFestivalDates["-"] = { date: "-" };
        state.eventDays = allFestivalDates;
        state.programDate = allFestivalDates[Object.keys(allFestivalDates)[0]];
        this.setState({});
      })
      .catch(error => {
        console.log(error);
      });
    window.addEventListener("scroll", this.onScroll.bind(this));
    window.addEventListener("resize", this.resize.bind(this));
    let logoHidden = window.pageYOffset > scrollTreshold ? true : false;
    this.setState({ logoHidden: logoHidden });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll.bind(this));
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.setState({});
  }

  onClickLanguage(lang) {
    state.language = lang;
    this.setState({});
  }

  onMobileMenuClick() {
    let opened = !this.state.mobileMenuOpened;
    this.setState({ mobileMenuOpened: opened });
  }

  onMobileMenuItemClick(item) {
    let hasSubMenu = item.subMenu !== undefined;
    if (!hasSubMenu) this.setState({ mobileMenuOpened: false });
  }

  onMobileSubmenuClick(subItem) {
    if (subItem === this.state.subMenuOpened) subItem = null;
    this.setState({ subMenuOpened: subItem });
  }

  // remove on conformation
  onScroll() {
    let shouldBeVisible = window.pageYOffset > scrollTreshold;
    if (shouldBeVisible !== this.state.logoHidden) {
      this.setState({ logoHidden: shouldBeVisible });
    }
  }

  getOneItem(item, hasSubMenu) {
    if (item.title === "imago") {
      return (
        <a href="https://imagosloveniae.net/" key="imago-icon">
          <div className="imago-menu-item-container">
            <img src="/images/imago-icon.png" alt="imago-icon" />
            IMAGO
          </div>
        </a>
      );
    } else if (hasSubMenu) {
      return (
        <NavLink to={{ pathname: "/" + item.subMenu[0].title }} activeStyle={hasSubMenu ? {} : { color: "var(--imago-light" }} key={item.title} exact={true}>
          {translations[state.language][item.title].toUpperCase()}
        </NavLink>
      );
    } else {
      return (
        <NavLink to={{ pathname: "/" + (homepage === item.title ? "" : item.title) }} activeStyle={hasSubMenu ? {} : { color: "var(--imago-light" }} key={item.title} exact={true}>
          {translations[state.language][item.title].toUpperCase()}
        </NavLink>
      );
    }
  }

  getMobileOneItem(item, hasSubMenu) {
    if (item.title === "imago") {
      return (
        <a href="https://imagosloveniae.net/" key="imago-icon">
          <div className="imago-menu-item-container mobile-icon">
            IMAGO
            <img src="/images/imago-icon.png" alt="imago-icon" />
          </div>
        </a>
      );
    } else if (hasSubMenu) {
      return (
        <div className="mobile-fake-link">
          <div>{translations[state.language][item.title]}</div>
          <div>
            <img id="submenu-arrow" alt="arrow" src={item.title === this.state.subMenuOpened ? "/images/submenu-arrow-down.png" : "/images/submenu-arrow-right.png"} />
          </div>
        </div>
      );
    } else {
      return (
        <NavLink to={{ pathname: "/" + (homepage === item.title ? "" : item.title) }} activeStyle={hasSubMenu ? {} : { color: "var(--imago-light" }} key={item.title} exact={true}>
          {translations[state.language][item.title].toUpperCase()}
        </NavLink>
      );
    }
  }

  getMobileHeaderDiv() {
    let headerDiv;

    headerDiv = menuItemsObject.map((mainItem, i) => {
      let hasSubMenu = mainItem.subMenu !== undefined;
      let item = [
        <div onClick={() => this.onMobileMenuItemClick(mainItem)} key={"mobile-item " + mainItem.title}>
          {this.getMobileOneItem(mainItem, hasSubMenu)}
        </div>
      ];

      if (hasSubMenu) {
        item.push(
          <div className={"mobile-dropdown-content " + (this.state.subMenuOpened === mainItem.title ? "submenu-opened" : "")} key={"mobile-dropdown " + i}>
            {mainItem.subMenu.map(subItem => {
              return (
                <div onClick={() => this.onMobileMenuItemClick(subItem)} key={"mobile-subitem " + subItem.title}>
                  {this.getMobileOneItem(subItem)}
                </div>
              );
            })}
          </div>
        );
        return (
          <div className={"mobile-dropdown"} key={"dd" + i} onClick={() => this.onMobileSubmenuClick(mainItem.title)}>
            {item}
          </div>
        );
      }
      return item;
    });

    return headerDiv;
  }

  getHeaderDiv() {
    let headerDiv;

    headerDiv = menuItemsObject.map((mainItem, i) => {
      let hasSubMenu = mainItem.subMenu !== undefined;
      let item = [this.getOneItem(mainItem, hasSubMenu)];

      if (hasSubMenu) {
        item.push(
          <div className="dropdown-content" key={"dd-content" + i}>
            {mainItem.subMenu.map(subItem => {
              return this.getOneItem(subItem);
            })}
          </div>
        );
        return (
          <div className="dropdown" key={"dd" + i}>
            {item}
          </div>
        );
      } else return item;
    });

    return <div className="menu-items">{headerDiv}</div>;
  }

  getHeaderImage() {
    return (
      <div className="page-logo">
        <Link to="/">
          <img id="logo-moon-image" src="/images/noci-moon-logo.png" alt="" />
        </Link>
        <Link to="/">
          <img id="logo-text-image" src="/images/noci-text-logo.png" alt="" />
        </Link>
      </div>
    );
  }

  getMobileLanguageContainer() {
    return (
      <div className={"mobile-language-container mobile-fake-link"}>
        <label className={state.language === "sl" ? "selected-language" : ""} onClick={() => this.onClickLanguage("sl")}>
          SL
        </label>
        <label className={state.language === "en" ? "selected-language" : ""} onClick={() => this.onClickLanguage("en")}>
          EN
        </label>
      </div>
    );
  }

  getLanguageContainer() {
    return (
      <div className={"language-container"}>
        <label className={state.language === "sl" ? "selected-language" : ""} onClick={() => this.onClickLanguage("sl")}>
          SL
        </label>
        <label className={state.language === "en" ? "selected-language" : ""} onClick={() => this.onClickLanguage("en")}>
          EN
        </label>
      </div>
    );
  }

  getRoute(item) {
    return <Route key={item.title} exact path={"/" + (homepage === item.title ? "" : item.title)} render={props => <item.component {...props} artistsList={artists} locationsList={locations} />} />;
  }

  getAllRoutes() {
    return menuItemsObject.map(mainItem => {
      let hasSubMenu = mainItem.subMenu !== undefined;
      let item = [this.getRoute(mainItem)];

      if (hasSubMenu) {
        item.push(
          mainItem.subMenu.map(subItem => {
            return this.getRoute(subItem);
          })
        );
      }
      return item;
    });
  }

  getHeader() {
    let width = window.innerWidth;
    if (width <= 750) {
      return (
        <div className="mobile-menu-container">
          <div className="mobile-menu-header">
            <Link to="/">
              <img id="mobile-logo-moon-image" src="/images/noci-moon-logo.png" alt="" />
            </Link>
            <Link to="/">
              <img id="mobile-logo-text-image" src="/images/noci-text-logo.png" alt="" />
            </Link>
            <img id="mobile-menu-image" src="/images/menu.png" alt="" onClick={() => this.onMobileMenuClick()} />
          </div>
          <div className={"mobile-menu-content " + (this.state.mobileMenuOpened ? "menu-opened" : "")}>
            {this.getMobileHeaderDiv()}
            <div>{this.getMobileLanguageContainer()}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="menu-container">
          {this.getHeaderImage()}
          <div className="right-side-header">
            {this.getHeaderDiv()}
            {this.getLanguageContainer()}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <appState.Provider value={state}>
        <ScrollToTop>
          <div className="App">
            <header>{this.getHeader()}</header>
            <div className="main-page-container">
              {this.getAllRoutes()}
              <Route path="/artist/:id" render={props => <Artist {...props} artistsList={artists} locationsList={locations} />} />
              <Route path="/news/:id" component={OneNews} />
              <Route path="/donate" component={Donate} />
              <Route path="/all_news" component={AllNews} />
              <Route path="/media" component={Media} />
            </div>
          </div>
          <Footer key="2" />
        </ScrollToTop>
      </appState.Provider>
    );
  }
}

export { appState };
export { defaultLanguage };
export default withRouter(App);
