import React, { Component } from "react";
import L from "leaflet";
import { Map, TileLayer, Marker } from "react-leaflet";
import { getTranslatedString } from "../config/helperFunctions";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/LeafletMap.css";

let randomLocationsCoordinates = [{ lat: 46.051141, lng: 14.50621 }];

let markerIcon = L.icon({
  iconUrl: "/images/pin_unselected.svg",

  iconSize: [25, 32], // size of the icon
  iconAnchor: [12, 32], // point of the icon which will correspond to marker's location
});

let currentMarkerIcon = L.icon({
  iconUrl: "/images/pin_selected.svg",

  iconSize: [25, 32], // size of the icon
  iconAnchor: [12, 32], // point of the icon which will correspond to marker's location
});

export class LeafletMap extends Component {
  componentDidMount() {
    for (let index = 0; index < 100; index++) {
      let randomX = Math.random() * 0.01;
      let randomY = Math.random() * 0.01;
      randomLocationsCoordinates.push({ lat: 46.050134 + randomX, lng: 14.506894 - randomY });
    }
    this.setState({});
  }

  render() {
    let locations = this.props.locationsList;
    let language = this.props.language;
    let icon = markerIcon;

    let markers = "";
    if (locations !== null) {
      markers = locations.map((marker, index) => {
        let locationName = getTranslatedString(marker.title, language);
        let position = [
          randomLocationsCoordinates[index] === undefined ? 0 : randomLocationsCoordinates[index].lat,
          randomLocationsCoordinates[index] === undefined ? "" : randomLocationsCoordinates[index].lng
        ];

        if (marker.gpsCoordinates.lat !== 0 && marker.gpsCoordinates.long !== 0) position = [marker.gpsCoordinates.lat, marker.gpsCoordinates.long];

        if (marker.id === this.props.currentMarker) {
          icon = currentMarkerIcon;
        } else {
          icon = markerIcon;
        }

        return <Marker key={locationName} icon={icon} position={position} onClick={() => this.props.onMarkerClick(marker.id)} />;
      });
    }

    return (
      <Map center={[46.049595, 14.504248]} zoom={14} attributionControl={true} zoomControl={true} doubleClickZoom={true} scrollWheelZoom={true} dragging={true} animate={true} easeLinearity={0.35}>
        <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {markers}
      </Map>
    );
  }
}

export default LeafletMap;
