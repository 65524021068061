import React, { useContext } from "react";
import SimpleButton from "./SimpleButton";
import { Link } from "react-router-dom";
import { appState } from "../App";
import { translations } from "../config/translations.js";
import "./styles/Footer.css";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

let emailToSubscribe = "";

const Footer = () => {
  const state = useContext(appState);
  const language = state.language;

  const textWritten = text => {
    emailToSubscribe = text.target.value;
  };

  const onSignUpClick = () => {
    if (validateEmail(emailToSubscribe)) {
      alert("Mail sent to: " + emailToSubscribe);
    } else alert("Not valid email!");
  };

  const validateEmail = email => {
    // eslint-disable-next-line
    let matchEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return matchEmail.test(String(email).toLowerCase());
  };

  return (
    <footer className="footer">
      <div className="footer-financer">
        <div className="financer-container">
          <div className="financer-logo">
            <a href="https://ced-slovenia.eu/en/">
              <img src="/images/pokrovitelji/ustvarjalnaevropa.png" alt="" />
            </a>
          </div>
          <div className="financer-text">
            {translations[language].financer_disclaimer}
          </div>
        </div>
      </div>

      <div className="main-footer-container">
        <div className="footer-column">
          <a href="https://imagosloveniae.net/">
            <img id="footer-imago-logo" src="/images/imago-square.png" alt="" />
          </a>
        </div>

        <div className="footer-column footer-mobile">
          <div className="footer-imago-info">
            <div className="footer-title">{translations[language].contact}</div>
            <div>
              <b>{translations[language].imago}</b>
            </div>

            <div>
              <p>Gornji trg 16, Ljubljana</p>
              Tel.: +386 (0)1 241 66 00
              <br /> Fax: +386 (0)1 241 66 07
              <br />
              <b>E-mail: imago@siol.net</b>
            </div>
          </div>
        </div>

        <div className="footer-column">
          <div className="footer-title">{translations[language].donate_five_percent}</div>
          <div>
            <Link to="/donate">
              <SimpleButton text={translations[language].donate} />
            </Link>
          </div>
          <div>
            <div className="footer-title donate-title">
              <b>{translations[language].immediate_donation}</b>
            </div>
            <img id="paypal-img" src="/images/paypal.png" alt="paypal" />
          </div>
        </div>

        <div className="footer-column">
          <div>
            <div className="media-title">
              <Link to="/media">{translations[language].media}</Link>
            </div>

            <div className="footer-title">{translations[language].sign_up_news}</div>
            <form
              onSubmit={e => {
                e.preventDefault();
                onSignUpClick();
              }}
            >
              <input type="text" placeholder="Email" onChange={text => textWritten(text)} />
            </form>
          </div>
          <div>
            <SimpleButton text={translations[language].sign_up} onSelect={onSignUpClick} />
          </div>
          <div>
            <div className="footer-social-container">
              <a href="https://www.facebook.com/imago.sloveniae">
                <img src="/images/media_fb.png" alt="" />
              </a>

              <a href="https://twitter.com/ImagoSloveniae">
                <img src="/images/media_twitter.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-footer-container">
        © IMAGO SLOVENIAE 2019. {translations[language].copyrights_reserved}
      </div>
    </footer>
  );
};

export default Footer;
