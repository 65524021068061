import React from "react";
import "./styles/NewsComponent.css";
import { Link } from "react-router-dom";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const NewsComponent = props => {
  return (
    <div className="news-component-container">
      <div className="news-component-date">{props.date}</div>
      <div className="news-component-title">
        <Link to={`/news/${props.date}`}>{props.title}</Link>
      </div>
      <div className="news-component-content">
        <p>{props.content}</p>
      </div>
    </div>
  );
};

export default NewsComponent;
