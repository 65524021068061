import React, { Component } from "react";
import { translations } from "../config/translations.js";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/FilterToggle.css";


let isTime = true;

class FilterToggle extends Component {
  toggleFilter(toggle) {
    this.props.onToggle(toggle);
    isTime = !isTime;
    this.setState({});
  }
  render() {
    return (
      <div className="filter-main">
        <div className="filter-holder" onClick={() => this.toggleFilter(!isTime)}>
          <div className={"filter-handle " + (isTime ? "time-selected" : "locations-selected")} />
          <div className="filter-label">
            <div className={isTime ? "selected" : "unselected"}>
            {translations[this.props.language].time_filter}
            </div>
            <div className={isTime ? "unselected" : "selected"}>
            {translations[this.props.language].location_filter}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterToggle;
