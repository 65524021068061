import React, { useContext, useState } from "react";
import LeafletMap from "../components/LeafletMap";
import ArtistGrid from "../components/ArtistGrid";
import Loader from "../components/Loader";
import { appState } from "../App";
import { translations } from "../config/translations.js";
import { getTranslatedString } from "../config/helperFunctions.js";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./styles/Locations.css";

const Locations = () => {
  const state = useContext(appState);
  const language = state.language;
  const artistsList = state.events;
  const locationsList = state.locations;

  const [currentLocationName, setCurrentLocationName] = useState(null);
  const [currentLocationId, setCurrentLocationId] = useState(null);

  let copyArtists = [];

  const onMarkerClick = id => {
    locationsList.forEach(element => {
      if (element.id === id) {
        setCurrentLocationName(element);
        setCurrentLocationId(id);
      }
    });
  };

  const setAllLocations = () => {
    setCurrentLocationId(null);
  };

  let wholeGrid = [];
  if (artistsList !== null) {
    if (currentLocationId === null) copyArtists = artistsList.slice();
    else {
      artistsList.forEach(element => {
        if (currentLocationId === element.venueId) {
          copyArtists.push(element);
        }
      });
    }
    wholeGrid = <ArtistGrid language={language} filter={false} artistsList={copyArtists} locationsList={locationsList} displayDates={true} displayLocation={currentLocationName === null || currentLocationId === null ? translations[language].all_locations : getTranslatedString(currentLocationName.title, language).toUpperCase()} />;
  } else wholeGrid = <Loader />;

  let allLocationsButton =
    currentLocationId === null ? (
      <div />
    ) : (
      <div className="all-locations-button" onClick={() => setAllLocations()}>
        {"< " + translations[language].back_to_all_locations}
      </div>
    );

  return (
    <div className="main-container-mobile">
      <LeafletMap locationsList={locationsList} onMarkerClick={onMarkerClick} currentMarker={currentLocationId} language={language} />
      {allLocationsButton}
      <div className={"locations-grid-container " + (currentLocationId !== null ? "remove-margin" : "")}>{wholeGrid}</div>
    </div>
  );
};

export default Locations;
