import React from "react";
import NewsComponent from "../components/NewsComponent";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const AllNews = () => {
  return (
    <div className="main-container">
      <NewsComponent
        date="15. 01. 2019"
        title="Namenite del vaše dohodnine (0,5 %) Ustanovi Imago Sloveniae – Podoba Slovenije"
        content="Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem
            proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev
            do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine."
      />
      <NewsComponent
        date="12. 12. 2018"
        title="Posnetek otvoritvenega koncerta 30. Mednarodnega festivala Noči v stari Ljubljani, 3. del"
        content="Vljudno vas vabimo k ogledu posnetka zadnjega dela otvoritvenega koncerta 30. Mednarodnega festivala Noči
          v Stari Ljubljani, kjer smo gostili izjemno skupino glasbenih raziskovalcev in ustvarjalcev iz Argentine,
          združenih v Orkester tradicijskih glasbil in novih tehnologij. Posnetek si lahko ogledate v Arhivu oddaj
          RTV Slovenija na tej povezavi."
      />
      <NewsComponent
        date="15. 01. 2019"
        title="Namenite del vaše dohodnine (0,5 %) Ustanovi Imago Sloveniae – Podoba Slovenije"
        content="Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem
          proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev
          do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine."
      />
      <NewsComponent
        date="12. 12. 2018"
        title="Posnetek otvoritvenega koncerta 30. Mednarodnega festivala Noči v stari Ljubljani, 3. del"
        content="Vljudno vas vabimo k ogledu posnetka zadnjega dela otvoritvenega koncerta 30. Mednarodnega festivala Noči
        v Stari Ljubljani, kjer smo gostili izjemno skupino glasbenih raziskovalcev in ustvarjalcev iz Argentine,
        združenih v Orkester tradicijskih glasbil in novih tehnologij. Posnetek si lahko ogledate v Arhivu oddaj
        RTV Slovenija na tej povezavi."
      />
      <NewsComponent
        date="15. 01. 2019"
        title="Namenite del vaše dohodnine (0,5 %) Ustanovi Imago Sloveniae – Podoba Slovenije"
        content="Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem
        proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev
        do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine."
      />
      <NewsComponent
        date="12. 12. 2018"
        title="Posnetek otvoritvenega koncerta 30. Mednarodnega festivala Noči v stari Ljubljani, 3. del"
        content="Vljudno vas vabimo k ogledu posnetka zadnjega dela otvoritvenega koncerta 30. Mednarodnega festivala Noči
      v Stari Ljubljani, kjer smo gostili izjemno skupino glasbenih raziskovalcev in ustvarjalcev iz Argentine,
      združenih v Orkester tradicijskih glasbil in novih tehnologij. Posnetek si lahko ogledate v Arhivu oddaj
      RTV Slovenija na tej povezavi."
      />
      <NewsComponent
        date="15. 01. 2019"
        title="Namenite del vaše dohodnine (0,5 %) Ustanovi Imago Sloveniae – Podoba Slovenije"
        content="Spoštovani, na vas se obračamo s prošnjo, da 0,5 % plačane dohodnine, ki bi sicer ostala v državnem
      proračunu, namenite naši Ustanovi. Ustanova Imago Sloveniae – Podoba Slovenije je na seznamu upravičencev
      do donacij iz dohodnine, ki omogoča, da davčni zavezanec 0,5 % dohodnine."
      />
      <NewsComponent
        date="12. 12. 2018"
        title="Posnetek otvoritvenega koncerta 30. Mednarodnega festivala Noči v stari Ljubljani, 3. del"
        content="Vljudno vas vabimo k ogledu posnetka zadnjega dela otvoritvenega koncerta 30. Mednarodnega festivala Noči
    v Stari Ljubljani, kjer smo gostili izjemno skupino glasbenih raziskovalcev in ustvarjalcev iz Argentine,
    združenih v Orkester tradicijskih glasbil in novih tehnologij. Posnetek si lahko ogledate v Arhivu oddaj
    RTV Slovenija na tej povezavi."
      />
    </div>
  );
};

export default AllNews;
