import About from "../pages/About";
import Program from "../pages/Program";
import Simposium from "../pages/Simposium";
import SimposiumProgram from "../pages/SimposiumProgram";
import Locations from "../pages/Locations";
import Culinary from "../pages/Culinary";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

let homepage = "program";

let menuItemsObject = [
  { title: "program", component: Program },
  {
    title: "symposium",
    component: null,
    subMenu: [{ title: "symposium_program", component: SimposiumProgram }, { title: "symposium_about", component: Simposium }]
  },
  {
    title: "other_events",
    component: null,
    subMenu: [{ title: "culinary", component: Culinary }]
  },
  { title: "locations", component: Locations },
  { title: "about", component: About },
  { title: "imago", component: null }
];

export { menuItemsObject, homepage };
