import React from "react";
import "./styles/Loader.css";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const Loader = () => {
  return (
    <div className="artist-grid-loader" key="loader">
      <img alt="loading..." src="/images/loader.svg" />
    </div>
  );
};

export default Loader;
