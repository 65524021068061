import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { appState } from "../App";
import { translations } from "../config/translations.js";
import Dotdotdot from "react-clamp";
import { getTranslatedString, getShortenedString, getArtistLargeImage, getCountry } from "../config/helperFunctions.js";
import "./styles/ExpandedArtist.css";


const ExpandedArtist = props => {
  const state = useContext(appState);
  const language = state.language;
  let isIeOrEdge = state.isIeOrEdge;

  let artistData = props.artistData;
  let artistName, artistImage, desc, country;

  let w = window.innerWidth;

  if (artistData.title !== undefined) {
    artistName = getTranslatedString(artistData.title, language);
    artistImage = getArtistLargeImage(artistData);

    country = getCountry(artistName);
    artistName = artistName.replace(country, "");

    desc = getTranslatedString(artistData.description, language);

    let band = desc
      .substring(0, desc.indexOf("<hr"))
      .trim()
      .replace(/(\r\n|\n|\r)/gm, "<br/>");

    if (band.includes("<strong"))
      desc = desc
        .substring(desc.indexOf("<hr />") + 6, desc.length)
        .trim()
        .replace(/(\r\n|\n|\r)/gm, "<br/>")
        .replace(/(<br\/>)+/g, "<br/>");
    else {
      band = translations[language].no_band;
      desc = desc
        .trim()
        .replace(/(\r\n|\n|\r)/gm, "<br/>")
        .replace(/(<br\/>)+/g, "<br/>");
      if (desc.indexOf("<hr />") === 0) desc = desc.substring(6, desc.length);
    }
    desc = getShortenedString(desc, 500);
  }

  let expandedArtist;
  if (w < 500) {
    expandedArtist = (
      <div className={"artist-collapsible " + (props.expanded ? "expanded" + (isIeOrEdge ? " ie-expanded" : "") : "collapsed")}>
        {isIeOrEdge ? (
          <div className="artist-mobile-background" />
        ) : (
          <div className="artist-mobile-background">
            <div className="artist-mobile-background-color" />
            <img src={artistImage} alt="" />
          </div>
        )}
        <div className="artist-mobile-desc">
          <div>
            <div className="expanded-artist-title">
              <Dotdotdot clamp={2} className="two-line-text-clamp">
                {artistName}
              </Dotdotdot>
            </div>
            <div className="expanded-artist-country">{country}</div>
          </div>
          <div>
            <div className="description-container">
              <div>
                <div className="band-description-container">
                  <div className="band-description">
                    <Dotdotdot clamp={4} className="four-line-text-clamp">
                      {desc}
                    </Dotdotdot>
                  </div>
                </div>
                <i>
                  <Link to={`/artist/${artistData.id}`}>{translations[language].read_more} ></Link>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (isIeOrEdge) {
      expandedArtist = (
        <div className={"artist-collapsible " + (props.expanded ? "expanded" : "collapsed")}>
          <div className="description-container">
            <div>
              <div className="expanded-artist-title">
                <Dotdotdot clamp={3} className="three-line-text-clamp">
                  {artistName}
                </Dotdotdot>
              </div>
              <div className="expanded-artist-country">
                <Dotdotdot clamp={2} className="two-line-text-clamp">
                  {country}
                </Dotdotdot>
              </div>
            </div>
            <div>
              <div className="band-description-container">
                <div className="band-description">
                  <Dotdotdot clamp={4} className="four-line-text-clamp">
                    {desc}
                  </Dotdotdot>
                </div>
              </div>
              <i>
                <Link to={`/artist/${artistData.id}`}>{translations[language].read_more} ></Link>
              </i>
            </div>
          </div>
          <div className="artist-image-ie">
            <img src={artistImage} alt="" />
            <Link to={`/artist/${artistData.id}`} />
            <div className="exit-button" onClick={() => props.onSelect(-1)}>
              <img src="/images/cancel.png" alt="" />
            </div>
          </div>
        </div>
      );
    } else {
      expandedArtist = (
        <div className={"artist-collapsible " + (props.expanded ? "expanded" : "collapsed")}>
          <div className="description-container">
            <div>
              <div className="expanded-artist-title">
                <Dotdotdot clamp={3} className="three-line-text-clamp">
                  {artistName}
                </Dotdotdot>
              </div>
              <div className="expanded-artist-country">
                <Dotdotdot clamp={2} className="two-line-text-clamp">
                  {country}
                </Dotdotdot>
              </div>
            </div>
            <div>
              <div className="band-description-container">
                <div className="band-description">
                  <Dotdotdot clamp={4} className="four-line-text-clamp">
                    {desc}
                  </Dotdotdot>
                </div>
              </div>
              <i>
                <Link to={`/artist/${artistData.id}`}>{translations[language].read_more} ></Link>
              </i>
            </div>
          </div>
          <div className="artist-image">
            <img src={artistImage} alt="" />
            <Link to={`/artist/${artistData.id}`} />
            <div className="exit-button" onClick={() => props.onSelect(-1)}>
              <img src="/images/cancel.png" alt="" />
            </div>
          </div>
        </div>
      );
    }
  }

  return expandedArtist;
};

export default ExpandedArtist;
