import React from "react";
import "./styles/SimposiumItem.css";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "core-js/stable";
import "regenerator-runtime/runtime";

const SimposiumItem = props => {
  let itemTitle = props.title;
  let itemHour = props.hour;
  let itemContent = props.content;

  return (
    
    <div className="symposium-item-container">
      {itemHour !== undefined ? (
        <div className="item-hour">
          {itemHour}
        </div>
      ) : (
        <div />
      )}
      {itemTitle !== undefined ? (
        <div className="item-title">
          {itemTitle}
        </div>
      ) : (
        <div />
      )}
      

      {itemContent !== undefined ? <div className="item-content" dangerouslySetInnerHTML={{ __html: itemContent }} /> : <div />}
    </div>
  );
};

export default SimposiumItem;
